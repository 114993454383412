import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _40d35ed3 = () => interopDefault(import('../pages/achievement/index.vue' /* webpackChunkName: "pages/achievement/index" */))
const _60bf3fea = () => interopDefault(import('../pages/advertise/index.vue' /* webpackChunkName: "pages/advertise/index" */))
const _13486ba4 = () => interopDefault(import('../pages/agreement/index.vue' /* webpackChunkName: "pages/agreement/index" */))
const _53070230 = () => interopDefault(import('../pages/calendar/index.vue' /* webpackChunkName: "pages/calendar/index" */))
const _72643ed0 = () => interopDefault(import('../pages/customer/index.vue' /* webpackChunkName: "pages/customer/index" */))
const _093452a6 = () => interopDefault(import('../pages/details/index.vue' /* webpackChunkName: "pages/details/index" */))
const _280c4638 = () => interopDefault(import('../pages/goals/index.vue' /* webpackChunkName: "pages/goals/index" */))
const _173a2cbf = () => interopDefault(import('../pages/maisoku/index.vue' /* webpackChunkName: "pages/maisoku/index" */))
const _70d40c08 = () => interopDefault(import('../pages/media/index.vue' /* webpackChunkName: "pages/media/index" */))
const _347280a9 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _0aafcabd = () => interopDefault(import('../pages/project/index.vue' /* webpackChunkName: "pages/project/index" */))
const _4f81f373 = () => interopDefault(import('../pages/response/index.vue' /* webpackChunkName: "pages/response/index" */))
const _63c2b3d0 = () => interopDefault(import('../pages/achievement/output.vue' /* webpackChunkName: "pages/achievement/output" */))
const _38a17b70 = () => interopDefault(import('../pages/achievement/registration.vue' /* webpackChunkName: "pages/achievement/registration" */))
const _3f0aea33 = () => interopDefault(import('../pages/advertise/analysis.vue' /* webpackChunkName: "pages/advertise/analysis" */))
const _022f1f4c = () => interopDefault(import('../pages/advertise/management.vue' /* webpackChunkName: "pages/advertise/management" */))
const _1eb0f210 = () => interopDefault(import('../pages/advertise/registration.vue' /* webpackChunkName: "pages/advertise/registration" */))
const _c308e76a = () => interopDefault(import('../pages/calendar/registration.vue' /* webpackChunkName: "pages/calendar/registration" */))
const _c08248aa = () => interopDefault(import('../pages/customer/registration.vue' /* webpackChunkName: "pages/customer/registration" */))
const _50d292ba = () => interopDefault(import('../pages/goals/analysis.vue' /* webpackChunkName: "pages/goals/analysis" */))
const _86e3c63c = () => interopDefault(import('../pages/goals/setting.vue' /* webpackChunkName: "pages/goals/setting" */))
const _76f8a7d4 = () => interopDefault(import('../pages/line/tracking/index.vue' /* webpackChunkName: "pages/line/tracking/index" */))
const _9ac9e6d2 = () => interopDefault(import('../pages/maisoku/export.vue' /* webpackChunkName: "pages/maisoku/export" */))
const _a02fe8c6 = () => interopDefault(import('../pages/maisoku/template.vue' /* webpackChunkName: "pages/maisoku/template" */))
const _a0238cb0 = () => interopDefault(import('../pages/response/registration.vue' /* webpackChunkName: "pages/response/registration" */))
const _edf665d0 = () => interopDefault(import('../pages/setting/announcement/index.vue' /* webpackChunkName: "pages/setting/announcement/index" */))
const _22123314 = () => interopDefault(import('../pages/setting/import/index.vue' /* webpackChunkName: "pages/setting/import/index" */))
const _053cbb32 = () => interopDefault(import('../pages/setting/mail-template.vue' /* webpackChunkName: "pages/setting/mail-template" */))
const _c3549d2e = () => interopDefault(import('../pages/setting/matching-mail.vue' /* webpackChunkName: "pages/setting/matching-mail" */))
const _760b8e84 = () => interopDefault(import('../pages/setting/shop.vue' /* webpackChunkName: "pages/setting/shop" */))
const _4f36e545 = () => interopDefault(import('../pages/setting/staff/index.vue' /* webpackChunkName: "pages/setting/staff/index" */))
const _2243c77d = () => interopDefault(import('../pages/setting/step-mail/index.vue' /* webpackChunkName: "pages/setting/step-mail/index" */))
const _453d0bff = () => interopDefault(import('../pages/setting/tag/index.vue' /* webpackChunkName: "pages/setting/tag/index" */))
const _67f0f685 = () => interopDefault(import('../pages/shop/document-template/index.vue' /* webpackChunkName: "pages/shop/document-template/index" */))
const _129ba960 = () => interopDefault(import('../pages/shop/message/index.vue' /* webpackChunkName: "pages/shop/message/index" */))
const _2ecb329e = () => interopDefault(import('../pages/message/line/edit.vue' /* webpackChunkName: "pages/message/line/edit" */))
const _3da1c540 = () => interopDefault(import('../pages/message/line/log.vue' /* webpackChunkName: "pages/message/line/log" */))
const _219d8ae0 = () => interopDefault(import('../pages/message/line/registration/index.vue' /* webpackChunkName: "pages/message/line/registration/index" */))
const _116c6190 = () => interopDefault(import('../pages/message/line/select.vue' /* webpackChunkName: "pages/message/line/select" */))
const _19a8a51c = () => interopDefault(import('../pages/message/line/send.vue' /* webpackChunkName: "pages/message/line/send" */))
const _eba9ffba = () => interopDefault(import('../pages/message/mail/log.vue' /* webpackChunkName: "pages/message/mail/log" */))
const _4cff8a6a = () => interopDefault(import('../pages/message/mail/registration.vue' /* webpackChunkName: "pages/message/mail/registration" */))
const _2cb43826 = () => interopDefault(import('../pages/message/mail/select.vue' /* webpackChunkName: "pages/message/mail/select" */))
const _dfadca8e = () => interopDefault(import('../pages/message/mail/send.vue' /* webpackChunkName: "pages/message/mail/send" */))
const _1951a73a = () => interopDefault(import('../pages/setting/announcement/registration.vue' /* webpackChunkName: "pages/setting/announcement/registration" */))
const _7b356d45 = () => interopDefault(import('../pages/setting/import/registration.vue' /* webpackChunkName: "pages/setting/import/registration" */))
const _2347b4cf = () => interopDefault(import('../pages/setting/real-estate-agent/property-range.vue' /* webpackChunkName: "pages/setting/real-estate-agent/property-range" */))
const _e0a210d4 = () => interopDefault(import('../pages/setting/staff/registration.vue' /* webpackChunkName: "pages/setting/staff/registration" */))
const _0bfcb344 = () => interopDefault(import('../pages/setting/step-mail/registration.vue' /* webpackChunkName: "pages/setting/step-mail/registration" */))
const _e9d6407e = () => interopDefault(import('../pages/message/line/registration/success.vue' /* webpackChunkName: "pages/message/line/registration/success" */))
const _54b0df33 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _45913f34 = () => interopDefault(import('../pages/message/mail/edit/_id.vue' /* webpackChunkName: "pages/message/mail/edit/_id" */))
const _e4284b6a = () => interopDefault(import('../pages/agreement/log/_id.vue' /* webpackChunkName: "pages/agreement/log/_id" */))
const _a29cac90 = () => interopDefault(import('../pages/agreement/registration/_id.vue' /* webpackChunkName: "pages/agreement/registration/_id" */))
const _61e90bb2 = () => interopDefault(import('../pages/customer/edit/_id.vue' /* webpackChunkName: "pages/customer/edit/_id" */))
const _217d9b9e = () => interopDefault(import('../pages/media/registration/_id.vue' /* webpackChunkName: "pages/media/registration/_id" */))
const _abcdec6e = () => interopDefault(import('../pages/project/registration/_id.vue' /* webpackChunkName: "pages/project/registration/_id" */))
const _d73822a6 = () => interopDefault(import('../pages/response/add/_id.vue' /* webpackChunkName: "pages/response/add/_id" */))
const _51133580 = () => interopDefault(import('../pages/setting/announcement/_id.vue' /* webpackChunkName: "pages/setting/announcement/_id" */))
const _d1511626 = () => interopDefault(import('../pages/setting/staff/_id.vue' /* webpackChunkName: "pages/setting/staff/_id" */))
const _43b0f5b6 = () => interopDefault(import('../pages/setting/step-mail/_id.vue' /* webpackChunkName: "pages/setting/step-mail/_id" */))
const _41c483c8 = () => interopDefault(import('../pages/shop/message/_id.vue' /* webpackChunkName: "pages/shop/message/_id" */))
const _5d40f9fb = () => interopDefault(import('../pages/achievement/_id.vue' /* webpackChunkName: "pages/achievement/_id" */))
const _7ddaffd4 = () => interopDefault(import('../pages/agreement/_id.vue' /* webpackChunkName: "pages/agreement/_id" */))
const _7ee5d6a8 = () => interopDefault(import('../pages/client-history/_id.vue' /* webpackChunkName: "pages/client-history/_id" */))
const _1893b538 = () => interopDefault(import('../pages/customer/_id.vue' /* webpackChunkName: "pages/customer/_id" */))
const _4c857070 = () => interopDefault(import('../pages/media/_id.vue' /* webpackChunkName: "pages/media/_id" */))
const _07894f36 = () => interopDefault(import('../pages/project/_id.vue' /* webpackChunkName: "pages/project/_id" */))
const _6b72ba2e = () => interopDefault(import('../pages/property/_property.vue' /* webpackChunkName: "pages/property/_property" */))
const _73ba757d = () => interopDefault(import('../pages/property/_property/contact/index.vue' /* webpackChunkName: "pages/property/_property/contact/index" */))
const _725b13ab = () => interopDefault(import('../pages/property/_property/contact/confirm.vue' /* webpackChunkName: "pages/property/_property/contact/confirm" */))
const _9aa806a6 = () => interopDefault(import('../pages/property/_property/contact/send.vue' /* webpackChunkName: "pages/property/_property/contact/send" */))
const _61cc369b = () => interopDefault(import('../pages/response/_id.vue' /* webpackChunkName: "pages/response/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/achievement",
    component: _40d35ed3,
    name: "achievement"
  }, {
    path: "/advertise",
    component: _60bf3fea,
    name: "advertise"
  }, {
    path: "/agreement",
    component: _13486ba4,
    name: "agreement"
  }, {
    path: "/calendar",
    component: _53070230,
    name: "calendar"
  }, {
    path: "/customer",
    component: _72643ed0,
    name: "customer"
  }, {
    path: "/details",
    component: _093452a6,
    name: "details"
  }, {
    path: "/goals",
    component: _280c4638,
    name: "goals"
  }, {
    path: "/maisoku",
    component: _173a2cbf,
    name: "maisoku"
  }, {
    path: "/media",
    component: _70d40c08,
    name: "media"
  }, {
    path: "/privacy",
    component: _347280a9,
    name: "privacy"
  }, {
    path: "/project",
    component: _0aafcabd,
    name: "project"
  }, {
    path: "/response",
    component: _4f81f373,
    name: "response"
  }, {
    path: "/achievement/output",
    component: _63c2b3d0,
    name: "achievement-output"
  }, {
    path: "/achievement/registration",
    component: _38a17b70,
    name: "achievement-registration"
  }, {
    path: "/advertise/analysis",
    component: _3f0aea33,
    name: "advertise-analysis"
  }, {
    path: "/advertise/management",
    component: _022f1f4c,
    name: "advertise-management"
  }, {
    path: "/advertise/registration",
    component: _1eb0f210,
    name: "advertise-registration"
  }, {
    path: "/calendar/registration",
    component: _c308e76a,
    name: "calendar-registration"
  }, {
    path: "/customer/registration",
    component: _c08248aa,
    name: "customer-registration"
  }, {
    path: "/goals/analysis",
    component: _50d292ba,
    name: "goals-analysis"
  }, {
    path: "/goals/setting",
    component: _86e3c63c,
    name: "goals-setting"
  }, {
    path: "/line/tracking",
    component: _76f8a7d4,
    name: "line-tracking"
  }, {
    path: "/maisoku/export",
    component: _9ac9e6d2,
    name: "maisoku-export"
  }, {
    path: "/maisoku/template",
    component: _a02fe8c6,
    name: "maisoku-template"
  }, {
    path: "/response/registration",
    component: _a0238cb0,
    name: "response-registration"
  }, {
    path: "/setting/announcement",
    component: _edf665d0,
    name: "setting-announcement"
  }, {
    path: "/setting/import",
    component: _22123314,
    name: "setting-import"
  }, {
    path: "/setting/mail-template",
    component: _053cbb32,
    name: "setting-mail-template"
  }, {
    path: "/setting/matching-mail",
    component: _c3549d2e,
    name: "setting-matching-mail"
  }, {
    path: "/setting/shop",
    component: _760b8e84,
    name: "setting-shop"
  }, {
    path: "/setting/staff",
    component: _4f36e545,
    name: "setting-staff"
  }, {
    path: "/setting/step-mail",
    component: _2243c77d,
    name: "setting-step-mail"
  }, {
    path: "/setting/tag",
    component: _453d0bff,
    name: "setting-tag"
  }, {
    path: "/shop/document-template",
    component: _67f0f685,
    name: "shop-document-template"
  }, {
    path: "/shop/message",
    component: _129ba960,
    name: "shop-message"
  }, {
    path: "/message/line/edit",
    component: _2ecb329e,
    name: "message-line-edit"
  }, {
    path: "/message/line/log",
    component: _3da1c540,
    name: "message-line-log"
  }, {
    path: "/message/line/registration",
    component: _219d8ae0,
    name: "message-line-registration"
  }, {
    path: "/message/line/select",
    component: _116c6190,
    name: "message-line-select"
  }, {
    path: "/message/line/send",
    component: _19a8a51c,
    name: "message-line-send"
  }, {
    path: "/message/mail/log",
    component: _eba9ffba,
    name: "message-mail-log"
  }, {
    path: "/message/mail/registration",
    component: _4cff8a6a,
    name: "message-mail-registration"
  }, {
    path: "/message/mail/select",
    component: _2cb43826,
    name: "message-mail-select"
  }, {
    path: "/message/mail/send",
    component: _dfadca8e,
    name: "message-mail-send"
  }, {
    path: "/setting/announcement/registration",
    component: _1951a73a,
    name: "setting-announcement-registration"
  }, {
    path: "/setting/import/registration",
    component: _7b356d45,
    name: "setting-import-registration"
  }, {
    path: "/setting/real-estate-agent/property-range",
    component: _2347b4cf,
    name: "setting-real-estate-agent-property-range"
  }, {
    path: "/setting/staff/registration",
    component: _e0a210d4,
    name: "setting-staff-registration"
  }, {
    path: "/setting/step-mail/registration",
    component: _0bfcb344,
    name: "setting-step-mail-registration"
  }, {
    path: "/message/line/registration/success",
    component: _e9d6407e,
    name: "message-line-registration-success"
  }, {
    path: "/",
    component: _54b0df33,
    name: "index"
  }, {
    path: "/message/mail/edit/:id?",
    component: _45913f34,
    name: "message-mail-edit-id"
  }, {
    path: "/agreement/log/:id?",
    component: _e4284b6a,
    name: "agreement-log-id"
  }, {
    path: "/agreement/registration/:id?",
    component: _a29cac90,
    name: "agreement-registration-id"
  }, {
    path: "/customer/edit/:id?",
    component: _61e90bb2,
    name: "customer-edit-id"
  }, {
    path: "/media/registration/:id?",
    component: _217d9b9e,
    name: "media-registration-id"
  }, {
    path: "/project/registration/:id?",
    component: _abcdec6e,
    name: "project-registration-id"
  }, {
    path: "/response/add/:id?",
    component: _d73822a6,
    name: "response-add-id"
  }, {
    path: "/setting/announcement/:id",
    component: _51133580,
    name: "setting-announcement-id"
  }, {
    path: "/setting/staff/:id",
    component: _d1511626,
    name: "setting-staff-id"
  }, {
    path: "/setting/step-mail/:id?",
    component: _43b0f5b6,
    name: "setting-step-mail-id"
  }, {
    path: "/shop/message/:id",
    component: _41c483c8,
    name: "shop-message-id"
  }, {
    path: "/achievement/:id",
    component: _5d40f9fb,
    name: "achievement-id"
  }, {
    path: "/agreement/:id",
    component: _7ddaffd4,
    name: "agreement-id"
  }, {
    path: "/client-history/:id?",
    component: _7ee5d6a8,
    name: "client-history-id"
  }, {
    path: "/customer/:id",
    component: _1893b538,
    name: "customer-id"
  }, {
    path: "/media/:id",
    component: _4c857070,
    name: "media-id"
  }, {
    path: "/project/:id",
    component: _07894f36,
    name: "project-id"
  }, {
    path: "/property/:property?",
    component: _6b72ba2e,
    name: "property-property",
    children: [{
      path: "contact",
      component: _73ba757d,
      name: "property-property-contact"
    }, {
      path: "contact/confirm",
      component: _725b13ab,
      name: "property-property-contact-confirm"
    }, {
      path: "contact/send",
      component: _9aa806a6,
      name: "property-property-contact-send"
    }]
  }, {
    path: "/response/:id",
    component: _61cc369b,
    name: "response-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
